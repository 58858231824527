import React from 'react';
import styled, { css } from 'styled-components';
import { MainContentColumnWrapper } from '../../components/layout/Layout';
import ngsImage from '../../assets/images/headerNGS_bg.webp';
import ngsMobileImage from '../../assets/images/headerNGS_mobile_bg.webp';
import { forScreenAtLeast1000pxWide } from '../../styles/mediaQueries';
import {
  TextLarge,
  Title,
  Upheader,
} from '../../components/atoms/Typography/Typography';
import ScrollIcon from '../../assets/images/Scroll.inline.svg';
import { ScrollAnimation, animation } from '../../styles/GlobalStyles';
import { descriptionSectionId } from './constants';

const IntroSection = (): JSX.Element => {
  const scrollToDescriptionSection = () => {
    const descriptionSection = document.querySelector<HTMLElement>(
      `#${descriptionSectionId}`,
    );
    if (!descriptionSection) {
      return;
    }
    window.scrollTo({
      top: descriptionSection.offsetTop - 60,
    });
  };

  return (
    <Wrapper>
      <MainContentColumnWrapper>
        <picture>
          <source media="(min-width: 1000px)" srcSet={ngsImage} />
          <Image src={ngsMobileImage} />
        </picture>
        <InnerWrapper>
          <Title $color="primary">AbNGS Database</Title>
          <TextLarge $color="delicate">
            Large database of immunoglobulin sequences for immunological and
            machine learning applications.
          </TextLarge>
          <ScrollButton onClick={scrollToDescriptionSection}>
            <ScrollIcon />
            <Upheader $color="delicate">Learn more</Upheader>
          </ScrollButton>
        </InnerWrapper>
      </MainContentColumnWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  aspect-ratio: 375 / 809;
  ${forScreenAtLeast1000pxWide(css`
    aspect-ratio: 64 / 27;
  `)}
`;

const Image = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

const InnerWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  max-width: 550px;

  ${Title} {
    font-size: 48px;
    ${forScreenAtLeast1000pxWide(css`
      font-size: 70px;
    `)}
  }

  ${TextLarge} {
    font-size: 18px;
    ${forScreenAtLeast1000pxWide(css`
      font-size: 22px;
    `)}
  }
`;

const ScrollButton = styled.button`
  display: flex;
  align-items: center;
  align-self: flex-start;
  padding: 0;
  background: none;
  border: none;
  outline: none;
  cursor: pointer;

  svg {
    margin-right: 10px;
    ${animation(0, 2, ScrollAnimation)};
    animation-iteration-count: infinite;
  }
`;

export default IntroSection;
