import React from 'react';
import Layout from '../components/layout/Layout';
import { FooterBoxContent } from '../components/layout/Footer';
import IntroSection from '../pages-components/ngs/IntroSection';
import DescriptionSection from '../pages-components/ngs/DescriptionSection';

const NgsPage = (): JSX.Element => {
  return (
    <Layout
      description="AbNGS Database. Large database of immunoglobulin sequences for immunological and machine learning applications."
      title="AbNGS Database"
      footerBoxContent={FooterBoxContent.NgsInvitationToContact}
    >
      <IntroSection />
      <DescriptionSection />
    </Layout>
  );
};

export default NgsPage;
